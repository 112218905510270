.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Radikal, sans-serif;
}

.welcomeTextContainer {
  margin-bottom: 20px;
}

.welcomeTextArea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  resize: vertical;
}

.uploadContainer {
  margin-bottom: 20px;
}

.photoItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.photoPreview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
}

.captionInput {
  flex-grow: 1;
  padding: 5px;
  margin-right: 10px;
}

.deleteButton {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.saveButton, .cancelButton {
  border: 3px solid #606060;
  padding: 0.9em 1em;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7em !important;
  background-color: transparent;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 6px 6px 0 0 rgba(255, 0, 255, 1), 12px 12px 0 0 rgba(0, 255, 255, 1), -6px -6px 0 0 rgba(255, 255, 0, 1);
  margin-right: 10px;
}

.saveButton {
  background-color: #4CAF50;
  color: white;
}

.cancelButton {
  background-color: #f44336;
  color: white;
}

@media (max-width: 600px) {
  .container {
    padding: 10px;
  }
  
  .photoItem {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .photoPreview {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }
  
  .captionInput {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}