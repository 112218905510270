.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Radikal, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  max-width: 150px;
  height: auto;
}

.photoContainer {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.taskPhoto {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.photoCaption {
  font-size: 1.1em;
  margin-bottom: 15px;
  text-align: center;
}

.uploadContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.uploadButton {
  border: 3px solid #606060;
  padding: 0.9em 1em;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.7em !important;
  background-color: transparent;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 6px 6px 0 0 rgba(255, 0, 255, 1), 12px 12px 0 0 rgba(0, 255, 255, 1), -6px -6px 0 0 rgba(255, 255, 0, 1);
  max-width: calc(100% - 24px);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.fileInput {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.errorMessage {
  color: #fc036f;
  margin-top: 10px;
  text-align: center;
}

.progressDisplay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 20px;
}

.navButtonContainer {
  width: 40px;
  display: flex;
  justify-content: center;
}

.photoNumber {
  flex-grow: 1;
  text-align: center;
  font-size: 1.1em;
}

.navButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
}

.passwordContainer, .welcomeContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Radikal, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.passwordHeader, .welcomeHeader {
  text-align: center;
  margin-bottom: 20px;
}

.passwordForm, .teamNameForm {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.passwordInput, .teamNameInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 3px solid #606060;
  border-radius: 3px;
  font-size: 16px;
  box-sizing: border-box;
}

.passwordButton, .teamNameButton {
  border: 3px solid #606060;
  padding: 0.9em 1em;
  text-align: center;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.7em !important;
  background-color: #ffffff;
  color: #000000;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 6px 6px 0 0 rgba(255, 0, 255, 1), 12px 12px 0 0 rgba(0, 255, 255, 1), -6px -6px 0 0 rgba(255, 255, 0, 1);
  max-width: calc(100% - 24px);
  width: 100%;
  box-sizing: border-box;
}

.passwordErrorMessage, .teamNameErrorMessage {
  color: #fc036f;
  margin-top: 10px;
  text-align: center;
}

.welcomeText {
  text-align: center;
  margin-bottom: 20px;
}

.uploadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  border-radius: 8px;
}

@media (max-width: 600px) {
  .container, .passwordContainer, .welcomeContainer {
    padding: 10px;
  }
  .header {
    font-size: 1.5em;
  }
  .photoContainer {
    padding: 15px;
  }
  .photoCaption {
    font-size: 1em;
  }
  .uploadButton, .passwordButton, .teamNameButton {
    font-size: 1em;
    padding: 15px 20px;
  }
  .logo {
    max-width: 100px;
  }
}

@media (max-height: 600px) and (orientation: landscape) {
  .container, .passwordContainer, .welcomeContainer {
    padding: 10px;
  }
  .header {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  .photoContainer {
    padding: 10px;
    margin-bottom: 10px;
  }
  .photoCaption {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
  .uploadButton, .passwordButton, .teamNameButton {
    padding: 8px 15px;
    font-size: 0.9em;
  }
}