.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Radikal, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.timerDisplay {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 20px;
}

.clueContainer {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clueContent {
  font-size: 1.1em;
  margin-bottom: 15px;
}

.locationButton {
  border: 3px solid #606060;
  padding: 0.9em 1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 10px 0 20px; /* Increased bottom margin */
  font-weight: 500;
  line-height: 1.7em !important;
  background-color: transparent;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 6px 6px 0 0 rgba(255, 0, 255, 1), 12px 12px 0 0 rgba(0, 255, 255, 1), -6px -6px 0 0 rgba(255, 255, 0, 1);
  max-width: calc(100% - 24px);  /* Subtracting the maximum shadow width */
  width: 100%;
  box-sizing: border-box;
}

.locationButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.errorMessage {
  color: #fc036f;
  margin-top: 10px;
  text-align: center; /* Center the error message */
}

.restartContainer {
  margin-top: auto;
  padding-top: 20px;
  display: flex;
  justify-content: center; /* Center the restart button */
}

.restartButton {
  background-color: #fc036f;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

@media (max-width: 600px) {
  .container {
    padding: 10px;
  }
  .header {
    font-size: 1.5em;
  }
  .timerDisplay {
    font-size: 1em;
  }
  .clueContainer {
    padding: 15px;
  }
  .clueContent {
    font-size: 1em;
  }
  .locationButton, .restartButton {
    width: calc(100% - 24px);  /* Subtracting the maximum shadow width */
    font-size: 1em;
    padding: 15px 20px;
    margin: 10px auto 20px; /* Increased bottom margin */
  }
}


@media (max-height: 600px) and (orientation: landscape) {
  .container {
    padding: 10px;
  }
  .header {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  .timerDisplay {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
  .clueContainer {
    padding: 10px;
    margin-bottom: 10px;
  }
  .clueContent {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
  .locationButton, .restartButton {
    padding: 8px 15px;
    font-size: 0.9em;
  }
}

.logo {
  max-width: 150px;
  height: auto;
}

@media (max-width: 600px) {
  .logo {
    max-width: 100px;
  }
}

.passwordContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Radikal, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.passwordHeader {
  text-align: center;
  margin-bottom: 20px;
}

.passwordForm {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.passwordInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 3px solid #606060;
  border-radius: 3px;
  font-size: 16px;
  box-sizing: border-box;
}

.passwordButton {
  border: 3px solid #606060;
  padding: 0.9em 1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 10px 0;
  font-weight: 500;
  line-height: 1.7em !important;
  background-color: #ffffff; /* Light background for contrast */
  color: #000000; /* Dark text for visibility */
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 6px 6px 0 0 rgba(255, 0, 255, 1), 12px 12px 0 0 rgba(0, 255, 255, 1), -6px -6px 0 0 rgba(255, 255, 0, 1);
  max-width: calc(100% - 24px);
  width: 100%;
  box-sizing: border-box;
}

.passwordButton:hover {
  background-color: #f0f0f0; /* Slightly darker on hover for feedback */
}

.passwordErrorMessage {
  color: #fc036f;
  margin-top: 10px;
  text-align: center;
}

@media (max-width: 600px) {
  .passwordButton {
    font-size: 16px;
    padding: 15px 20px;
    background-color: #ffffff; /* Ensure background color on mobile */
    color: #000000; /* Ensure text color on mobile */
  }
}
  
  .passwordInput, .passwordButton {
    font-size: 16px;
    padding: 15px 20px;
  }

.verificationContainer {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
}

.verificationContainer h3 {
  margin-bottom: 15px;
  font-size: 1.2em;
}

.answerButton {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #ffffff;
  border: 2px solid #606060;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
}

.answerButton:hover {
  background-color: #f0f0f0;
}

.answerButton.selected {
  background-color: #606060;
  color: white;
}

.submitAnswerButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitAnswerButton:hover {
  background-color: #45a049;
}

.submitAnswerButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .verificationContainer {
    padding: 15px;
  }

  .answerButton, .submitAnswerButton {
    font-size: 14px;
    padding: 8px 15px;
  }
}

.extraHint {
  font-style: italic;
}

.centeredText {
  text-align: center;
}

.fullScreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlayContent {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
}

.overlayContent h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.overlayContent p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

/* Popup styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 80%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup h3 {
  margin-top: 0;
  color: #333;
}

.popupButtons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.confirmButton, .cancelButton {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.confirmButton {
  background-color: #4CAF50;
  color: white;
}

.confirmButton:hover {
  background-color: #45a049;
}

.cancelButton {
  background-color: #fc036f;
  color: white;
}

.cancelButton:hover {
  background-color: #e0035f;
}

/* Glowing button effect */
@keyframes glowing {
  0% {
    box-shadow: 0 0 5px #fc036f;
  }
  50% {
    box-shadow: 0 0 20px #fc036f;
  }
  100% {
    box-shadow: 0 0 5px #fc036f;
  }
}

.glowingButton {
  animation: glowing 2s infinite;
}

.glowingButton:hover {
  animation: none;
  box-shadow: 0 0 20px #fc036f;
}