.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Radikal, sans-serif;
}

.logoutButton {
  float: right;
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form {
  margin-bottom: 30px;
}

.input, .select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.button {
  border: 3px solid #606060;
  padding: 0.9em 1em;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7em !important;
  background-color: transparent;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 6px 6px 0 0 rgba(255, 0, 255, 1), 12px 12px 0 0 rgba(0, 255, 255, 1), -6px -6px 0 0 rgba(255, 255, 0, 1);
  margin-right: 10px;
}

.errorMessage {
  color: #f44336;
  margin-bottom: 10px;
}

.passwordGenerator {
  margin-bottom: 30px;
}

.generatedPassword {
  font-weight: bold;
  margin-top: 10px;
}

.taskList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}


.editLink {
  display: inline-block;
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.deleteButton {
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .container {
    padding: 10px;
  }
  
  .taskList {
    grid-template-columns: 1fr;
  }
}

.taskItem {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.shareUrl {
  word-break: break-all;
  margin-bottom: 10px;
}

.editLink, .deleteButton {
  align-self: flex-start;
}

.deleteConfirmOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleteConfirmDialog {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  max-width: 400px;
  width: 100%;
}

.deleteConfirmButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.confirmDeleteButton, .cancelDeleteButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirmDeleteButton {
  background-color: #f44336;
  color: white;
}

.cancelDeleteButton {
  background-color: #ccc;
  color: black;
}