.editTaskContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Radikal, sans-serif;
}

.clueContainer {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.fieldGroup {
  margin-bottom: 15px;
}

.fieldGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input, .textarea, .select {
  width: 100%;
  padding: 10px;
  border: 2px solid #606060;
  border-radius: 3px;
  font-size: 16px;
}

.textarea {
  min-height: 100px;
  resize: vertical;
}

.error {
  color: #fc036f;
  font-size: 14px;
  margin-top: 5px;
}

.removeButton, .addButton, .submitButton, .cancelButton {
  border: 3px solid #606060;
  padding: 0.9em 1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 5px;
  font-weight: 500;
  line-height: 1.7em !important;
  background-color: #ffffff;
  color: #000000;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.removeButton:hover, .addButton:hover, .submitButton:hover, .cancelButton:hover {
  background-color: #f0f0f0;
}

.removeButton {
  background-color: #fc036f;
  color: white;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .editTaskContainer {
    padding: 10px;
  }
  
  .input, .textarea, .select, .removeButton, .addButton, .submitButton, .cancelButton {
    font-size: 14px;
    padding: 8px 15px;
  }
}